.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #ffffff;
    border-radius: 0.3rem;
}
.calendar-header{
    color:#0097ff;
}
.list-group-item, .list-group-item:hover{ z-index:unset; }
.dash-sidebar {
    // float:left;
	/* display: -ms-flexbox; */
	display: flex !important;

	flex-direction: column !important;
	width: 200px;
    margin-bottom: 10px;
	// height: 100vh;
	background-color: #f8f9fa !important;
    padding: 1rem !important;
    flex-shrink: 0 !important;
	border-right: 1px solid #c8d2dc;
    font-family: barlow,sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #3c4b64;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.dash-sidebar nav{
    flex-direction: column !important;
}
.dash-sidebar nav .nav-link{
    text-align: left;
    padding-left: 5px;
}
.dash-body {
    min-height:600px;
}
.dash-body form{
    display: flex !important;
	flex-direction: column !important;
}

.dash-footer {
	display: -ms-flexbox;
	display: flex;
	-ms-flex: 0 1 44px;
	flex: 0 1 44px;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 14px;
	color: #9da5b1;
	border-top: 1px solid #c8d2dc;
}
.cd-sidebar-nav {
    position: relative;
    margin-top: 26px;
    -ms-flex: 1;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
}
.cd-sidebar-nav .cd-nav-dropdown {
    position: relative;
    transition: background .25s ease-in-out;
}
.cd-sidebar-brand svg {
    height: 50px;
    overflow: hidden;
    vertical-align: middle;
}
.cd-sidebar-brand {
    //display: -ms-flexbox;
    //display: flex;
    // -ms-flex-align: center;
    // align-items: center;
    // -ms-flex-pack: center;
    // justify-content: center;
    // height: 105px;
    // margin:10px auto;
    // padding-top:25px;
    position: relative;
    width: 100%;
    margin-bottom: -1px;
    padding-top: 90px;
    padding-bottom: 30px;
    border: 1px solid #e1e7ec;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    overflow: hidden;
}
.cd-sidebar-brand .user-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background-position: center;
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-size: cover;
}
.cd-sidebar-brand .user-info {
    display: table;
    position: relative;
    width: 100%;
    padding: 0 18px;
    z-index: 5;
}
.cd-sidebar-brand .user-info .user-avatar, .cd-sidebar-brand .user-info .user-data {
    display: table-cell;
    vertical-align: top;
}
.cd-sidebar-brand .user-info .user-avatar {
    position: relative;
    width: 115px;
}
.user-avatar {
    width: 100px;
    margin: 0px auto;
}
.cd-sidebar-brand .user-info .user-data {
    padding-top: 48px;
    padding-left: 12px;
}
.cd-sidebar-brand .info-label {
    display: block;
    position: absolute;
    top: 18px;
    right: 18px;
    height: 26px;
    padding: 0 12px;
    border-radius: 13px;
    background-color: #ffffff;
    color: #606975;
    font-size: 12px;
    line-height: 26px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
    cursor: pointer;
    }
.cd-sidebar-brand p, .cd-sidebar-brand h5{
    text-align: center;
}
.cd-sidebar-brand h5{
    font-weight: 700;
}
.cd-sidebar-brand img{
    border-radius: 15px 15px;
}
.dash-body{
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    width:100%;
    height: 100%;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}
.dash-body .cd-content{
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}
#dash-widgets .card {
    border: none;
}
.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    //border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}
.bg-yellow {
    background-color: #f39c12 !important;
}
.small-box, .info-box {
    border-radius: 15px;
    position: relative;
    display: block;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
}
.small-box > .inner {
    padding: 10px;
}
.small-box h3 {
    font-size: 38px;
    font-weight: bold;
    margin: 0 0 10px 0;
    white-space: nowrap;
    padding: 0;
}
.info-box-icon > i, .icon > i, .inner > h3, .inner > p, .info-box-content > span, .small-box-footer > i, .fa-search {
    color: #ffffff;
}
.small-box h3, .small-box p {
    z-index: 5;
}
.small-box p {
    float: left;
    font-size: 15px;
}
.small-box .icon {
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 0;
    font-size: 70px;
    color: rgba(0, 0, 0, 0.15);
}
.small-box > .small-box-footer {
    position: relative;
    padding: 3px 0;
    color: #fff;
    display: block;
    z-index: 10;
    text-decoration: none;
    text-align: right;
    // margin-right: 10px;
    margin-top: 10px;
}
.dash-body card{
    margin-bottom: 10px;
}
.cd-sidebar-nav .cd-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 48px;
    font-family: barlow,sans-serif;
    font-size: 18px;
    padding-left: 40px;
    font-weight: 400;
    line-height: 22px;
    color: #0d7bfb;
    text-decoration: none;
}
.cd-sidebar-nav .cd-nav-icon {
    height: 22px;
    margin-right: 15px;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}
.cd-sidebar-nav .cd-nav-dropdown-items {
    max-height: 0;
    padding: 0;
    margin: 0;
    overflow-y: hidden;
    list-style: none;
    transition: max-height .25s ease-in-out;
}
.cd-sidebar-nav .cd-nav-dropdown-items .cd-nav-link {
    padding-left: 64px;
}
.dash-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 1 105px;
    flex: 0 1 105px;
    -ms-flex-align: center;
    align-items: right;
    margin-bottom: 40px;
    // border-bottom: 1px solid #c8d2dc;
    margin-left: 324px;
}
.navbar-nav-profile {
    width: 200px;
    text-align: right;
}
