// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import '~@fortawesome/fontawesome-free/css/all.css';
@import "~intl-tel-input/build/css/intlTelInput.min.css";

// Variables
@import 'variables';
@import 'dashboard';
@import 'kustomer';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "~noty/src/noty.scss";
@import "~noty/src/themes/mint.scss";
@import "~ckeditor4/skins/moono-lisa/editor.css";
// @import "../../vendor/needim/noty/src/noty.scss";
// @import "../../vendor/needim/noty/src/themes/mint.scss";
// @import "../../vendor/ckeditor/ckeditor/skins/moono-lisa/editor.css";


//jQuery UI
//@import '~jquery-ui/themes/base/all.css';
.iti__flag {background-image: url("~intl-tel-input/build/img/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("~intl-tel-input/build/img/flags@2x.png");}
}
#header{
    height: auto;
    width: 100%;
    padding: 0;
    margin: 0;
    // float: left;
    background: #fff;
    // background: url(../images/ke-flag.png) top center no-repeat #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: relative;
    z-index: 99;
}
.search-box{
    border-radius: 25px 0 0 25px;
    border-right: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    width: 100%;
    padding: 0 1em;
    border: 1px solid #999;
    border-right-color: rgb(153, 153, 153);
    border-right-style: solid;
    border-right-width: 1px;
    border-right: none;
    color: #404040;
    font-family: "Montserrat", sans-serif;
    font-size: 0.889em;
    font-style: italic;
    font-weight: 300;
    height: 40px;
    outline-offset: -2px;
}
.nav-login{
    // border: 1px solid #fff;
    background:#0097ff;
    border-radius: 20px;
    background-color: grey;
    font-size: 14px;
    font-weight: 600;
    padding: 2px 18px;
    border-color: white;
    color: white;
    transition: all 150ms linear;
}
.nav-register{
    // border: 1px solid #fff;
    background:#0097ff;border-radius: 20px;
    background-color: #0097ff;
    font-size: 14px;
    font-weight: 600;
    padding: 2px 18px;
    border-color: white;
    color: white;
    transition: all 150ms linear;
}
#app-menu .navbar-nav, #lang-switcher .navbar-nav, #search-bar ul{
    flex-direction: row;
    float: right;
}
#menu-bar{
    width:100%;
    height:auto;
    padding:0;
    margin:0;
    float:left;
    background: #f4f4f4;
    border-top: 1px solid #eee;
}
#menu-bar{
    width: 100%;
    background:#f4f4f4;
}
#menu-bar li {
    list-style-type: none;
    list-style: none;
        list-style-image: none;
    list-style-image: none;
    padding: 0;
    margin: 0;
    // display: inline-block;
    float: right;
    width: auto;
    height: auto;
    border-right: 1px solid rgba(255,255,255, 0.1);
    color:0097ff;
}
#menu-bar li>a:hover{
    color:#0097ff;
    background-color: #f4f4f4;
}
#menu-bar ul li a, #main-menu ul li span.nolink {
    padding: 18px 20px;
    margin: 0;
    float: left;
    width: auto;
    height: auto;
    font-size: 1em;
    font-weight: 500;
    text-decoration: none;
    color: #0097ff;
    font-family: "Roboto Condensed", "Roboto", "Helvetica Neue", Arial, sans-serif !important;
    line-height: 0.5em;
    text-transform: uppercase;
    outline: none;
        outline-color: currentcolor;
    outline-color: inherit;
    -webkit-transition: all ease-in-out .35s;
    -o-transition: all ease-in-out .35s;
    transition: all ease-in-out .35s;
}
#lang-switcher {
    width: 20%;
    float: right;
    padding: 0;
    margin: 20px 0 0 0;
}
#lang-switcher .navbar-nav {
    width: auto;
    height: auto;
    padding: 0 0 0 10px;
    margin: 0;
    float: right;
    text-align: right !important;
    border-left: 1px solid #999;
}
#lang-switcher li {
    list-style-type: none;
    list-style: none;
    list-style-image: none;
    padding: 0;
    margin: 0 8px 0 0;
    display: inline-block;
}
#app-menu {
    width: 80%;
    float: right;
    padding: 0;
    margin: 20px 0 0 0;
    // text-align: right;
}
#app-menu .menu {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0 15px 0 0;
    float: right;
    text-align: right;
    border: 0;
}
#app-menu li {
    list-style-type: none;
    list-style: none;
        list-style-image: none;
    list-style-image: none;
    padding: 0;
    margin: 0 8px 0 0;
    display: inline-block;
}
#search-bar {
    width: 80%;
    height: auto;
    padding: 0;
    margin: 0;
    float: right;
    text-align: right !important;
}
#search-bar li{
    /*width:100%;*/
    list-style: none;
    /*padding: 0 0 0 10px;*/
}
#search-bar p{
    margin-right: 8px;
}
.s-form{
    margin-right:60px;
}
.brand-area{
    margin: 20px 0 0 0;
}
.slogan-area{
    margin: 15px 0 0 0;
}
.slogan-area h4{
    text-align: center;
    font-weight: bold;
    text-decoration-style: wavy;
    color:#495057;
    font-size:1rem;
    margin-top:10px;
}
#tenders-card .card-header{
    color: #333333 !important;
    font-weight: 700;
    font-size: 1.5rem;
}
#tenders-card .card-header img{
   width: 50px;
   height:33px;
   border-top-left-radius: 50% 50%;
   border-top-right-radius: 50% 50%;
   border-bottom-right-radius: 50% 50%;
   border-bottom-left-radius: 50% 50%;
}
#tenders-card .la-calendar{
    color: $red;
}
#tenders-card .la-hand-holding-usd{
    color: $blue;
}
#tenders-card li{
    font-size: 1rem;
}
main{
    clear:both;
}
.justify-content-center{
    clear: both;
}
#footer {
    float: left;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    background: rgb(78, 102, 113);
    border-top: 5px solid #0097ff;
}
#sectors_select{
    height: 15px;
    border: 2px solid #ddd;
    border-radius: 3px 3px;
}
.contact-box i.fa {
    font-size: 20px;
    color: #0097ff;
    float: left;
    width: 20px;
    margin-right: 15px;
    text-align: right;
}
.contact-box h5{
    color: #0097ff;
}
.contact-details {
    padding-left: 35px;
}
// .la-at{
//     font-size: 2rem;
//     float: left;
//     padding: 0;
//    margin: 5px 20px 0 0;
//    color:white;
// }
// #navbarSupportedContent ul li ::before {
//     float: left;
//     padding: 0;
//     margin: 0 10px 0 0;
//     font-family: 'Lineawesome';
//     font-size: 2.5rem;
// }
// .ico-contact::before {
//     content: "&#xf1fa;";
// }
// #navbarSupportedContent{
//     width:200px;
// }
// #navbarSupportedContent .mr-auto .nav-link{
//     font-size: 13px;
//     text-transform: uppercase;
//     font-weight: 700;
// }

.s-form button{
    box-sizing: border-box;
    border-radius: 0 25px 25px 0;
    background-size: 20px;
    background-image: url("http://localhost/appmr/public/img/icon_magnify.png");
    background-image: linear-gradient(transparent, transparent), url("http://localhost/appmr/public/img/icon_magnify.png");
    background-color: #0097ff;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: auto 27px;
    border: 0;
    height: 40px;
    margin-right: 0;
    overflow: hidden;
    position: absolute;
    //right: 9px;
    text-indent: -999em;
    //top: 9px;
    width: 47px;
    color:#fff;
    display: inline-block;
    font-size: 1rem;
}
.news .card-body a, .training .card-body a{
    font-size: 0.9em;
    text-transform: uppercase;
    color: #6c757d;
    margin-bottom: 5px;
    padding: 0;
    display: block;
    text-decoration: none;
    font-weight: bold;
}
.news .card-body a:hover, .training .card-body a:hover{
    color: #0097ff;
}
.news .card-body strong{
    display: block;
    padding: 20px 20px 8px 0;
    font-size: 0.7em;
    text-transform: uppercase;
    color: #999999;
}
.training .card-body strong{
    display: block;
    padding: 20px 20px 8px 0;
    font-size: 0.9em;
    text-transform: uppercase;
    color: #999999;
}
.crt-widget-panel {
    min-height:min-content;
}
.crt-widget-carousel {
    min-height:min-content;
}
//Page Banner
#sub-bnr {
    background: #4d4d4f;
    background-repeat: repeat;
    background-image: none;
    background-size: auto;
    min-height: 400px;
    position: relative;
    padding: 0px;
    box-sizing: border-box;
}
.position-center-center {
    position: absolute;
    top: 50%;
    left:30%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    width:100%;

}
#sub-bnr h1 {
    border-left: 10px solid #FFF;
    display: initial;
    padding-left: 5px;
    color: #FFFF;
    text-align: center;
}
h1, .h1 {
    font-size: 36px;
}
.lead {
    font-size: 21px !important;
    font-weight: 500 !important;
    line-height: 1.4 !important;
    margin: 15px 0 !important;
    color: #ffffff !important;
}

//Knoweledge Academy
.uou-tabs {
    position: relative;
    margin: 0 0 24px;
}
.uou-tabs .ui-tabs-nav {
    padding-left: 0;
    list-style: none;
    margin: 0;
}
.uou-tabs .ui-tabs-nav::after {
    clear: both;
}
.uou-tabs .ui-tabs-nav::before, .uou-tabs .ui-tabs-nav::after {
    content: " ";
    display: table;
}
.uou-tabs .ui-tabs-nav::after {
    clear: both;
}
.ui-tabs-vertical .uou-tabs .ui-tabs-nav
{
  width: auto;
}
@media only screen and (min-width: 767px)
{
  .uou-tabs .content
  {
      margin-left: 262.5px;
  }
}
 .ui-tabs-vertical .uou-tabs .ui-tabs-nav li.active a {
    border-left-color: #0099ff;
    border-top-color: #f4f4f4;
}
.uou-tabs .ui-tabs-nav li.active a {
    color: #333333;
    background: #f4f4f4;
    border: 3px solid #f4f4f4;
    border-top-color: rgb(244, 244, 244);
    border-left-color: rgb(244, 244, 244);
    border-top-color: #0099ff;
}
.uou-tabs .ui-tabs-nav li a {
    display: block;
    border: 3px solid transparent;
    color: #333333;
    padding: 8px 24px;
}
.ui-tabs-vertical .uou-tabs .content {
    margin-left: 220.5px;
    padding-left:20px;
}
.ui-tabs-vertical .uou-tabs .content {
    //margin-left: 160px;
    border-top: 0;
    border-left: 3px solid #f4f4f4;
}
.ui-tabs-nav{
    float: left;
    width:  auto;
}
.cols2 {
    column-count: 2;
}
.list-with-number {
    padding-left: 0;
    list-style: none;
    counter-reset: my-badass-counter;
}
.list-with-number li{
    margin-bottom:20px;
}
.list-with-number li::before {
    content: counter(my-badass-counter);
    counter-increment: my-badass-counter;
    margin-right: 10px;
    height: 30px;
    width: 30px;
    background-color: #0099ff;
    color: #ffffff;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
}

//News pages
.uou-block-7f:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}
.blog-post-content {
    position: relative;
}
.uou-block-7f {
    position: relative;
    // display: block;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 20px 30px;
    font-size: 14px;
    z-index: 0;
    box-shadow: 0 0 0 0 #E4E4E4;
    border: 1px solid #D9D9D9;
    border-top-color: rgb(217, 217, 217);
    border-top-style: solid;
    border-top-width: 1px;
    border-top: 2px solid #0099ff;
    -webkit-transition: box-shadow 500ms cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: box-shadow 500ms cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: box-shadow 500ms cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: box-shadow 500ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: box-shadow 500ms cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}
.hub .meta {
    padding-top: 0px !important;
}
.blog-post-content .meta {
    padding-top: 50px;
    text-transform: uppercase;
    color: #737373;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    word-wrap: break-word;
}
.blog-post-content .meta span::after {
    content: '\2022';
    margin: 0 10px;
}
.hub h1 {
    font-size: 36px !important;
}
.uou-block-7f h1 {
    margin-top: 12px;
    font-size: 20px;
}
.uou-block-7f a.btn {
    text-transform: capitalize;
    padding: 6px 8px;
    border-radius: 20px;
    box-sizing: border-box;
    border-width: 2px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 18px;
    border-color: #66615B;
    margin-bottom: 10px;
}
.blog-content a {
    color: #093eb6;
}
.fa-download::before {
    content: "\f019";
}
.uou-sidebar {
    position: relative;
    width: 100%;
    padding-bottom: 50px;
}
.uou-sidebar .sidebar-title:first-child {
    margin-top: 0;
}
.uou-sidebar h5.sidebar-title {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 40px;
}
.list-widget {
    position: relative;
}
.list-widget ul {
    padding-left: 0;
    list-style: none;
}
.list-widget ul li:last-child {
    border-bottom: 1px solid #f4f4f4;
}
.list-widget ul li {
    position: relative;
    padding: 7px 0;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.list-widget ul li a {
    display: block;
    text-transform: uppercase;
    color: #737373;
    font-size: 13px;
}
.mt-5{
    width:800px;
    margin:0 auto;
    border: 1px solid #ddd;
    padding:20px;
    border-radius:5px 5px;
}
.uou-block-7f .thumb {
    width: 250px;
    height: auto;
    float: left;
    margin-right: 30px;
    border-radius: 4px;
}
.btn, .navbar .navbar-nav > li > a.btn {
    border-radius: 20px;
    box-sizing: border-box;
    border-width: 2px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 18px;
    border-color: #66615B;
    color: #66615B;
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
}
.uou-block-7f .meta {
    display: block;
    padding: 20px 20px 8px 0;
    font-size: 0.7em;
    text-transform: uppercase;
    color: #999999;
}
.uou-block-7f .meta a{
    color: #999999;
}
.uou-block-7f h1 a{
    font-size: 0.9em;
    text-transform: uppercase;
    color: #333333;
    margin-bottom: 5px;
    padding: 0;
    display: block;
    text-decoration: none;
    font-weight: bold;
}
.social-widget .uou-block-4b .social-icons {
    margin: 10px 0;
}
.uou-block-4b .social-icons {
    padding-left: 0;
    list-style: none;
    display: inline-block;
    margin-top: 48px;
    margin-bottom: 0;
    vertical-align: top;
}
.uou-block-4b .social-icons > li {
    float: left;
    margin-right: 10px;
}
.latest-post-widget .post-single img {
    position: absolute;
    left: 0;
    //top: 20px;
    width: 80px;
    border-radius: 4px;
    height: auto;
    float:left;
}
.latest-post-widget .post-single .post-title {
    //margin: 0;
    font-size: 13px;
    padding-top: 10px;
    color: #000;
    margin-bottom:10px;
    font-weight:10;
}
.latest-post-widget .post-single .post-title a {
    color: #000;

}
.latest-post-widget .post-single .meta {
    text-transform: uppercase;
    color: #737373;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    line-height: 16px;
}
.copyright{
    color:white;
    margin-top:10px;
}
.client-featured p{
    text-align:center;
}
.client-featured img{
    height:80px;
    opacity: 0.5;
}
.client-featured img:hover{
    opacity:1;
}

.client-featured a{
    margin-right:10px;
    margin-bottom:10px;
}
.spotlight{
    // margin-top:20px;
    height: 270px;
    width: 100%;
}
.error{
    color:red;
}
.blog-btn {
    padding: 10px;
}
#home-carousel{
    clear:both;
    margin-bottom: 20px;
}
#home-carousel .carousel-inner{
    height:400px;
    clear:both;
}
#impact-container{
    margin-top:20px;
    margin-bottom:20px;
}
.carousel-caption{
    background: #0097ff;
    position: absolute;
    opacity: 0.7;
    bottom: 0px;
    left:0px;
    right:0px;
    z-index:10;
    height:50%;
    // width:100%;
    padding-top:20px;
    padding-bottom: 20px;
    font-family:"Montserrat", sans-serif;
}
.carousel-caption h5{
    font-size:28px;
}
.page-bg{
    // background-image:url('../images/iia_pillars.png');
    background-image:url('http://localhost/appmr/public/images/iia_pillars.png');
    background-repeat: none;
    background-position: center;
    background-size: cover;
}
.card-header h4{
    font-weight:600;
    color:#0097ff;
}
.carousel-control-prev-icon, .carousel-control-next-icon{
    width:48px;
    height: 48px;
}
.home-btn-primary{
    background: rgb(0, 151, 255);
    border-color:white;
    color:white;
}
.home-btn-secondary{
    background: grey;
    border-color:white;
    color:white;
}
.other-markets h2{
    margin:30px 30px;
}
.other-markets img{
    height: 50px;
    width: 84px;
    initial-letter-align: middle;
}
.other-markets a{
    text-align: center;
    display:block;
}
.partner-section{
    background-color: white;
    margin-top: 30px;
    width:100%;
}
.home-news{
    width:100%;
}
.news-inner{
    margin:0 auto;
}
#suppliers-table, #pooled-suppliers-table, #requests-table, #pool-suppliers-table{
    border-radius: 5px 5px;
}
/*New footer*/
.col_white_amrc { color:#FFF;}
footer { width:100%; background-color:#263238; min-height:250px; padding:10px 0px 25px 0px ;}
.pt2 { padding-top:40px ; margin-bottom:20px ;}
footer p { font-size:13px; color:#CCC; padding-bottom:0px; margin-bottom:8px;}
.mb10 { padding-bottom:15px ;}
.footer_ul_amrc { margin:0px ; list-style-type:none ; font-size:14px; padding:0px 0px 10px 0px ; }
.footer_ul_amrc li {padding:0px 0px 5px 0px;}
.footer_ul_amrc li a{ color:#CCC;}
.footer_ul_amrc li a:hover{ color:#fff; text-decoration:none;}
.fleft { float:left;}
.padding-right { padding-right:10px; }

.footer_ul2_amrc {margin:0px; list-style-type:none; padding:0px;}
.footer_ul2_amrc li p { display:table; }
.footer_ul2_amrc li a:hover { text-decoration:none;}
.footer_ul2_amrc li i { margin-top:5px;}

.bottom_border { border-bottom:1px solid #323f45; padding-bottom:20px;}
.foote_bottom_ul_amrc {
	list-style-type:none;
	padding:0px;
	display:table;
	margin-top: 10px;
	margin-right: auto;
	margin-bottom: 10px;
	margin-left: auto;
}
.foote_bottom_ul_amrc li { display:inline;}
.foote_bottom_ul_amrc li a { color:#999; margin:0 12px;}

.social_footer_ul { display:table; margin:15px auto 0 auto; list-style-type:none;  }
.social_footer_ul li { padding-left:20px; padding-top:10px; float:left; }
.social_footer_ul li a { color:#CCC; border:1px solid #CCC; padding:8px;border-radius:50%;}
.social_footer_ul li i {  width:20px; height:20px; text-align:center;}

.social_ul { display:table; margin:0 auto 0 auto; list-style-type:none;  }
.social_ul li:first-child { padding-left:0px;}
.social_ul li { padding-left:10px; padding-top:10px; float:left; }
.social_ul li a { color:#1D9BF6; border:1px solid #1D9BF6; padding:8px;border-radius:50%;}
.social_ul li i {  width:20px; height:15px; text-align:center;}

/** Counter CSS**/
.counter {
  padding: 20px 0;
  border-radius: 5px;
}
.impact-odd{
    background-color:#1D9BF6 !important;
    margin-bottom: 10px;
}
.impact-even{
    background-color:#4e6671 !important;
    margin-bottom: 10px;
}
.impact-odd:hover, .impact-even:hover{
    opacity:0.5;
    text-decoration:khaki;
}
.count-title {
  font-size: 40px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  color:#fff;
}

.count-text {
  font-size: 13px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  color:#fff;
}

.fa-2x {
  margin: 0 auto;
  float: none;
  display: table;
  color: #fff;
}
.fa-check-circle{
    color:green;
}
.fa-window-close{
    color:red;
}

.accordion {
    font-family: Arial,Helvetica,sans-serif;
    transition: 0.4s;

}
a.accordion-header {
    font-size: 20px;
    cursor: pointer;
}
a.accordion-header:hover {
    background-color: #0097ff;
    color: #ffffff;
}
a.accordion-header.active-accordion {
    background-color: #0097ff;
    color: #ffffff;
}
.accordion-content-flex {
    height: 100%;
    padding: 10px;
}
a.accordion-header:after {
    content: '\25b6';
    font-weight: bold;
    float: right;
    margin-left: 10px;
    font-size: 14px;
}
a.accordion-header.active-accordion:after {
    content: '\25bc';
}
    /************ CSS Animation ***********/

.animation_acrd {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes swashIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 50% 50%;
        -webkit-transform: scale(0, 0);
    }
    90% {
        opacity: 1;
        -webkit-transform-origin: 50% 50%;
        -webkit-transform: scale(0.9, 0.9);
    }
    100% {
        opacity: 1;
        -webkit-transform-origin: 50% 50%;
        -webkit-transform: scale(1, 1);
    }
}
@keyframes swashIn {
    0% {
        opacity: 0;
        transform-origin: 50% 50%;
        transform: scale(0, 0);
    }
    90% {
        opacity: 1;
        transform-origin: 50% 50%;
        transform: scale(0.9, 0.9);
    }
    100% {
        opacity: 1;
        transform-origin: 50% 50%;
        transform: scale(1, 1);
    }
}
.swashIn {
    -webkit-animation-name: swashIn;
    animation-name: swashIn;
}
footer iframe{
    max-height:300px;
    overflow: scroll;
}
.promo-code-input{
    display:none;
}
#promo{
    color:cornflowerblue;
}
hr{
    border-top: 1px solid #1D9BF6;
}
.our-work .card{
    // height:200px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #fff !important;
    // padding: 1rem !important;
    // flex-shrink: 0 !important;
    border:none;
	border-right: 1px solid #c8d2dc;
}
.our-work h4{
    text-align:center;
    margin-bottom: 15px;
}
.our-work h4, .our-work h5{
    font-weight:bold;
}
#tier_silver, #tier_bronze, #tier_gold{
    appearance: none;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-clip: content-box;
    border: 2px solid rgba(255,252,229,1);
    background-color: rgba(255,252,229,1);
  }

  input[name="tier"]:checked {
    background-color: #000;
    padding: 4px;
    border: 2px solid #000;
  }
.zoom{
    overflow: hidden;
}
.zoom img {
    transition-duration: 4s;
    margin: 0 auto;
    display: block;
}
.zoom img:hover {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    z-index: 0;
}
